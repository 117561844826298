









import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateAuthorityDialog from './UpdateAuthorityDialog.vue';
import { namespace } from 'vuex-class';
import { Contact } from '../../types';
import { ApiResponse } from '@/components/types';
import { MapContactToWriteContact } from '../../model';
import { CHANGE_CONTACT } from '@/modules/contact/store';
import { WriteContact } from '@/modules/conservatorshipToContact/types';

const Contact = namespace('contact');

@Component({
  components: {
    UpdateAuthorityDialog
  }
})
export default class UpdateHealthInsuranceDialog extends Vue {
  @Prop({ type: Object }) value!: Contact | null;
  @Prop({ type: Boolean, default: false }) dialog!: boolean;

  @Contact.Action(CHANGE_CONTACT) updateContact!: (params: WriteContact) => Promise<ApiResponse>;

  get healthInsurance() {
    if (!this.value) return null;

    return MapContactToWriteContact(this.value);
  }

  get iDialog() {
    return this.dialog;
  }

  set iDialog(value: boolean) {
    this.$emit('update:dialog', value);
  }

  async update(value: WriteContact) {
    const { error } = await this.updateContact(value);

    return { error: error };
  }
}
